import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import './App.css';

const MainChat = () => {
  return (
    <div className="chat-container">
      <iframe
        src="/soa_orlando/backend-orlando"
        title="Assistant Principal"
        className="chat-frame"
        allow="microphone"
      />
    </div>
  );
};

const SecondChat = () => {
  return (
    <div className="chat-container">
      <iframe
        src="/soa_orlando/backend-example"
        title="Second Assistant"
        className="chat-frame"
        allow="microphone"
      />
    </div>
  );
};

const App = () => {
  const openChat = (chatType) => {
    const url = chatType === 'orlando' 
      ? 'https://orlando.smartofficeassist.com/soa_orlando/backend-orlando'
      : 'https://orlando.smartofficeassist.com/soa_orlando/backend-example';
    
    window.open(url, '_blank', 'width=400,height=600');
  };

  return (
    <Router>
      <div className="app">
        <nav className="navigation">
          <div className="nav-buttons">
            <Link to="/" className="nav-button" onClick={() => openChat('orlando')}>
              <img src="/orlando-agent-.png" alt="Assistant Principal" />
              <span>Assistant Principal</span>
            </Link>
            <Link to="/second-chat" className="nav-button" onClick={() => openChat('gary')}>
              <img src="/gary-agent-.png" alt="Second Assistant" />
              <span>Second Assistant</span>
            </Link>
          </div>
        </nav>
        <Routes>
          <Route path="/" element={<MainChat />} />
          <Route path="/second-chat" element={<SecondChat />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
